  <template>
  <div class="page-container">
    <section-title title="资料填写" show-asterisk></section-title>
    <!--    <scan-code v-model="form.imei"></scan-code>-->
    <cell-input
      class="imei"
      title="IMEI号"
      v-model="form.imei"
      disabled
    ></cell-input>
    <field-piker
      label="维修类型"
      placeholder="请选择维修类型"
      title="请选择维修类型"
      :columns="repairTypes"
      v-model="form.finish_type"
    >
    </field-piker>

    <field-piker
      label="维修方式"
      placeholder="请选择类型选择"
      title="维修类型选择"
      :columns="repairMethods"
      v-model="form.finish_mode"
    >
    </field-piker>

    <money v-model="form.money"></money>

    <section-title title="备注信息"></section-title>

    <adaptive-textarea
      v-model="form.remarks"
      placeholder="请输入备注信息(限50字内)"
      :max-length="50"
    ></adaptive-textarea>

    <section-title title="上传维修完成图片" show-asterisk></section-title>

    <div class="img-note">注：请按照以下示例图上传维修完成照片</div>

    <pic-uploader
      :src="form.img"
      @change="
        (url) => {
          form.img = url;
        }
      "
      :example="require('@/assets/images/examplePic/example15.png')"
    ></pic-uploader>

    <template v-if="form.finish_mode.indexOf('官方') !== -1">
      <section-title title="上传维修工单照片" show-asterisk></section-title>

      <div class="img-note">注：请请按照以下示例图上传维修工单照片</div>

      <pic-uploader
        :src="form.work"
        @change="
          (url) => {
            form.work = url;
          }
        "
        :example="require('@/assets/images/examplePic/example10.png')"
      ></pic-uploader>
    </template>

    <my-button
      class="confirm-btn"
      :type="isDone ? 'primary' : 'default'"
      :disabled="!isDone"
      @click="confirm"
      >提交申请
    </my-button>
  </div>
</template>

<script>
import { getFinishMode, getFinishType } from "@/api/register";
import {
  finishOrder,
  getOrderClaimsInfo,
  setRepairFinish,
} from "@/api/repairInfo";
import { isFilled } from "@/utils";

export default {
  name: "index",
  props: {
    id: [String, Number],
  },
  data() {
    return {
      form: {
        imei: "",
        finish_type: "",
        finish_mode: "",
        money: "",
        remarks: "",
        img: "",
        work: "",
      },
      repairTypes: [],
      repairMethods: [],
      isDone: false,
      openId: "",
      RepairId: "",
    };
  },
  mounted() {
    if (sessionStorage.getItem("RepairId")) {
      this.RepairId = sessionStorage.getItem("RepairId");
      sessionStorage.removeItem("RepairId");
    }

    getFinishType().then((res) => {
      this.repairTypes = res.data;
    });
    getFinishMode().then((res) => {
      this.repairMethods = res.data;
    });
    getOrderClaimsInfo(this.id).then((res) => {
      this.form.imei = (res && res.data && res.data.order.imei) || "";
    });
  },
  watch: {
    form: {
      handler(form) {
        this.checkForm(form);
      },
      deep: true,
    },
  },
  methods: {
    checkForm(form) {
      let isDone = true;
      Object.keys(form).forEach((key) => {
        if (
          key === "imei" ||
          key === "finish_type" ||
          key === "finish_mode" ||
          key === "money" ||
          key === "img"
        ) {
          if (!isFilled(form[key])) {
            isDone = false;
          }
        }
        if (this.form.finish_mode.indexOf("官方") !== -1 && key === "work") {
          if (!isFilled(form[key])) {
            isDone = false;
          }
        }
      });
      this.isDone = isDone;
    },
    selectRepairType(type) {
      this.form.finish_type = type;
    },
    selectRepairMethod(method) {
      this.form.finish_mode = method;
    },
    confirm() {
      let { userInfo } = this.$store.state;
      setRepairFinish(
        {
          // openid: userInfo.openid,
          claims_id: this.id,
          imei: this.form.imei,
          money: this.form.money,
          finish_type: this.form.finish_type,
          finish_mode: this.form.finish_mode,
          tem_img: this.form.img,
          tem_work: this.form.work,
          remarks: this.form.remarks,
          RepairId: this.RepairId,
        },
        this.openId
      )
        .then((res) => {
          if (res && res.code) {
            this.$myDialog({
              type: "success",
              title: res.msg || "提交成功",
              confirmButtonText: "查看详情",
              beforeClose: (action, done) => {
                if (action === "confirm") {
                  done();

                  this.$router.push("/RepairInfoDetail/" + res.data.id);
                } else {
                  done();
                }
              },
            });
          } else {
            this.$toast(res.msg || "审核申请提交失败");
          }
        })
        .catch((err) => {
          this.$toast((err && err.msg) || "审核申请提交失败");
        });
    },
  },
};
</script>

<style scoped lang="less">
.title {
  padding-left: 24px;
  margin: 40px 0 20px;
  font-weight: 800;
  font-size: 32px;
  color: #333333;
}

.cell-input {
  text-align: right;
  background: transparent;
}

.cell-input.money {
  color: @red;
}

.img-note {
  width: calc(100vw - 60px);
  min-height: 88px;
  background: #f6f8fe;
  border-radius: 16px;
  font-size: 28px;
  color: #333333;
  padding: 26px;
  margin-bottom: 30px;
  text-align: justify;
}

.confirm-btn {
  width: calc(100vw - 150px);
  margin: 30px auto 0;
}
::v-deep .imei input {
  background: #fff;
  color: #999;
}
</style>
